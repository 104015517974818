import { createRouter, createWebHashHistory } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import UserProfile from '../views/UserProfile.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import CheckEmail from '../views/CheckEmail.vue';
import AuditUsers from '../views/AuditUsers.vue';
import AuditDocuments from '../views/AuditDocuments.vue';
import AdminView from '../views/AdminView.vue';
import DocumentView from '../views/DocumentView.vue';
import UploadFiles from '../views/UploadFiles.vue';
import ForgotPasswordConfirm from '../views/ForgotPasswordConfirm.vue';
import PreClassificationForm from '../views/PreClassificationForm.vue';
import UpdateExpiredPassword from '../views/UpdateExpiredPassword.vue';
import ValidationForm from '../views/ValidationForm.vue';

import { cookieAsObject } from '../utils/cookieToObject';
import store from '../store/index';

function requireCookie(to, from, next) {
  const cookieObject = cookieAsObject(document.cookie);
  const accessToken = cookieObject.accessToken;
  if (accessToken == null) {
    next({ name: 'LoginView' });
  } else {
    next();
  }
}


const routes = [
  {
    path: '/',
    name: 'Init',
    component: LoginView,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/update-expired-password/:token/',
    name: 'UpdateExpiredPassword',
    component: UpdateExpiredPassword,
    props: true
  },
  {
    path: '/forgot-password/:token',
    name: 'ForgotPasswordConfirm',
    component: ForgotPasswordConfirm,
    props: true
  },

  {
    path: '/checkEmail',
    name: 'CheckEmail',
    component: CheckEmail,
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView,
    beforeEnter: requireCookie
  },
  {
    path: '/home/userProfile',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: requireCookie,
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView,
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-users',
    name: 'AuditUsers',
    component: AuditUsers,
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-documents',
    name: 'AuditDocuments',
    component: AuditDocuments,
    beforeEnter: requireCookie,
  },
  {
    path: '/document',
    name: 'DocumentView',
    component: DocumentView,
    beforeEnter: requireCookie,
  },
  {
    path: '/document/uploadFiles',
    name: 'UploadFiles',
    component: UploadFiles,
    beforeEnter: requireCookie,
  },
  {
    path: '/document/preclassification',
    name: 'PreClassificationForm',
    component: PreClassificationForm,
    beforeEnter: requireCookie,
  },
  {
    path: '/document/validation',
    name: 'ValidationForm',
    component: ValidationForm,
    beforeEnter: requireCookie,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});


// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  document.title = 'Datalake';
  store.dispatch('updateActiveRoute', to.path);
  next();
});

export default router;
